import { render, staticRenderFns } from "./Groupon.vue?vue&type=template&id=20b7ecfe&scoped=true&"
import script from "./Groupon.vue?vue&type=script&lang=js&"
export * from "./Groupon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b7ecfe",
  null
  
)

export default component.exports