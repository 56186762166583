<template>
  <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
  >
   <div class="news-list">
     <div class="news-item" v-for="(item,index) in list" :key="index" @click="toDetails(index)">
       <div class="news-header">
         <div class="news-img">
           <img :src="item.coverPic" alt="">
         </div>
         <div class="news-info">
           <p class="news-title">{{item.title}}</p>
           <p class="news-subtitle">{{item.subTitle}}</p>
         </div>
       </div>
       <div class="news-time-num">
         <p class="news-time">{{item.time}}</p>
         <div class="show-number">
           <van-icon name="eye-o" /><span class="num">{{item.watchNum}}</span>
         </div>
       </div>
     </div>
   </div>
  </van-list>
</template>

<script>
export default {
  name: "History",
  data(){
    return {
      list: [],
      loading: false,
      finished: false,
      limit:20,
      page:1
    }
  },
  methods: {
    onLoad() {
      this.getList();
    },
    getList(){
      this.$axios.get("/api/wxmp/news/list",{
          params:{
            page:this.page,
            limit:this.limit,
            deptId:this.$store.state.deptId
          }
      }).then((res) =>{
        console.log('res',res);
        this.list = this.list.concat(res.data.list);
        // 加载状态结束
        this.loading = false;
        if(res.data.totalPage <= this.page){
          this.finished = true;
          console.log('finished');
        }
        this.page = this.page + 1;
      }).catch((err) =>{
        console.log('err',err);
      })
    },
    toDetails(index){
      this.$router.push({name:'historyDetails',query:{newsId:this.list[index].newsId}})
    }
  }
}
</script>

<style scoped>
p{
  margin: 0;
  padding: 0;
}
.news-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 300px;
  position: relative;
}
.news-item +.news-item{
  border-top: 1px solid #f5f5f5;
}

.news-item .news-img{
  width: 100px;
  min-height: 80px;
}
.news-img img{
  max-width: 100%;
  padding: 10px;
}
.news-info{
  flex: 1;

}
.news-title{
  color: black;
  flex:1;
  padding-right: 5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}
.news-subtitle{
  color: #666;
  height: 3em;
  padding: 10px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
}

.van-icon{
  vertical-align: middle;
}
.news-time-num{
  display: flex;
  justify-content: space-between;
  padding: 0 10px 5px 10px;
  color: #666;
  font-size: 12px;
}
.show-number span{
  vertical-align: middle;
  padding: 0 2px;
  font-size: 12px;
}
</style>
